import React from "react"
import { Link } from "gatsby"

const Breadcrumb = ({ items, theme, color, center }) => {
  const linkColorClass = {
    white: 'has-text-white',
    dark: 'has-text-dark'
  }[theme]

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul style={{ justifyContent: center ? 'center' : 'flex-start' }}>
        {items.map((item, index) => (
          <li key={index} className="is-font-size-13">
            <BreadcrumbLink item={item} linkColorClass={linkColorClass} color={color} />
          </li>
        ))}
      </ul>
    </nav>
  )
}

const BreadcrumbLink = ({ item, linkColorClass, color }) => {
  if (item.link) {
    return (
      <Link to={item.link} className={`has-text-weight-bold ${linkColorClass}`} style={{ color: color }}>
        {item.label}
      </Link>
    )
  } else {
    return (
      <span className={`has-text-weight-bold ${linkColorClass}`} style={{ color: color }}>
        {item.label}
      </span>
    )
  }
}

export default Breadcrumb
