import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactFaq from "../components/contact-faq"
import ContactFormWithInfo from "../components/contact-form-with-info"

const Page = ({ data, pageContext }) => {
  const title = "Precisa de ajuda?"
  const post = data.wordpressMainPage

  return (
    <Layout>
      <SEO post={post} />
      <ContactFaq currentCategory={pageContext.taxonomy ? pageContext.taxonomy.slug : null} />
      <ContactFormWithInfo
        button_id="fale-conosco-button"
        post_id={post.post_id}
        title={title}
        last_input_name="Destinatário"
        last_input_placeholder="Encaminhar mensagem para..."
        text_name="Mensagem"
        text_placeholder="Sua mensagem"
        send_email={true}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordpressMainPage(name: {eq: "fale-conosco"}) {
      ...MainPageFull
    }
  }
`
