import React from "react"

class Questions extends React.Component {
  constructor(props) {
    super(props)

    this.state = { selected: -1 }
  }

  open(index) {
    this.setState({ selected: index })
  }

  close() {
    this.setState({ selected: -1 })
  }

  render() {
    const { selected } = this.state
    const { items } = this.props

    return (
      <div className="questions-component">
        {items.map((item, index) => {
          let contentItem
          let key

          if (index === selected) {
            key = `open-${index}`
            contentItem = <div className="item-content">
              <button className="button is-large is-fullwidth has-text-blue open" onClick={() => this.close()}>
                <span className="is-size-5 has-text-weight-bold">
                  {item.title}
                </span>
                <span className="icon is-small">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus"
                    className="svg-inline--fa fa-minus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                </span>
              </button>
              <div className="content content-16" dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          } else {
            key = `closed-${index}`
            contentItem = <div className="item-content">
              <button className="button is-large is-fullwidth has-text-black closed" onClick={() => this.open(index)}>
                <span className="is-size-5 has-text-weight-bold">
                  {item.title}
                </span>
                <span className="icon is-small">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus"
                    className="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                </span>
              </button>
            </div>
          }

          return (
            <div key={key}>
              <div className={`question-item ${(index === selected) ? 'open' : 'closed'}`}>
                {contentItem}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default Questions
