const Translations = {
  notifications: {
    success_email: "E-mail enviado com sucesso",
    error_email: "Houve um problema ao enviar o e-mail",
    error_message: "Houve um problema ao enviar sua mensagem",
    error_review: "Houve um problema ao enviar sua revisão",
    thanks: "Obrigado.",
    try_later: "Por favor tente mais tarde.",
  },
  form: {
    errors: {
      required: "Campo obrigatório.",
      phone: "Telefone inválido",
      email: "E-mail inválido",
      cpf: "CPF inválido.",
      cnpj: "CNPJ inválido.",
      cep: "CEP inválido.",
    }
  }
}

export default Translations
