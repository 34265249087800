import { graphql } from "gatsby"
import Page from "../templates/fale-conosco"

export default Page

export const pageQuery = graphql`
  query {
    wordpressMainPage(name: {eq: "fale-conosco"}) {
      ...MainPageFull
    }
  }
`
