import React from "react"
import { Field } from 'react-final-form'

const ErrorElement = ({ touched, error }) => {
  if (!touched || !error) return null

  return <p className="help is-danger">{error}</p>
}

export const SelectField = (props) => {
  return (
    <Field
      {...props}
      render={({ input, meta, options }) => (
        <div className="field">
          <div className="control">
            <select
              {...input}
              onChange={(event) => {
                input.onChange(event)
                props.onChange && props.onChange(event.target.value)
              }}
              onBlur={input.onBlur}
              value={props.value || input.value}
              className={buildClassName(["input", "is-rounded"], meta.touched, meta.error)}
            >
              <option>{props.placeholder}</option>
              {options.map(option => <option key={option.key || option.value} value={option.value}>{option.label}</option>)}
            </select>
            <ErrorElement touched={meta.touched} error={meta.error} />
          </div>
        </div>
      )}
    />
  )
}

export const InputField = (props) => {
  return (
    <Field
      {...props}
      type="text"
      render={({ input, meta }) => (
        <div className="field">
          <div className="control">
            <input
              {...input}
              placeholder={props.placeholder}
              className={buildClassName(["input", "is-rounded"], meta.touched, meta.error)}
            />
            <ErrorElement touched={meta.touched} error={meta.error} />
          </div>
        </div>
      )}
    />
  )
}

export const TextareaField = (props) => {
  return (
    <Field
      {...props}
      render={({ input, meta }) => (
        <div className="field">
          <div className="control">
            <textarea
              {...input}
              className={buildClassName(["textarea", "is-round-5"], meta.touched, meta.error)}
              placeholder={props.placeholder}
            />
            <ErrorElement touched={meta.touched} error={meta.error} />
          </div>
        </div>
      )}
    />
  )
}

const buildClassName = (classes, touched, error) => {
  if (touched) {
    if (error) {
      classes.push("is-danger")
    } else {
      classes.push("is-success")
    }
  }
  return classes.join(" ")
}
