import React, { useState, useEffect } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'
import emailValidator from "email-validator"
import formatString from "format-string-by-pattern"
import { Form } from 'react-final-form'
import { showSuccess, showError } from "../domain/notifications"
import { InputField, TextareaField, SelectField } from "./fields"
import Translations from "../domain/translations"

const validations = {
  required: value => (value ? undefined : Translations.form.errors.required),
  phone: value => (/^\(\d{2}\) \d{8,9}$/.test(value) ? undefined : Translations.form.errors.phone),
  email: value => (emailValidator.validate(value) ? undefined : Translations.form.errors.email),
  cpf: value => (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value) ? undefined : Translations.form.errors.cpf),
  cnpj: value => (/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value) ? undefined : Translations.form.errors.cnpj),
  cep: value => (/^\d{5}-\d{3}$/.test(value) ? undefined : Translations.form.errors.cep),
}

const parsers = {
  phone: formatString("(99) 999999999"),
  cpf: formatString("999.999.999-99"),
  cnpj: formatString("99.999.999/9999-99"),
  cep: formatString("99999-999"),
}

const doSendEmail = async (post_id, values, sucessCallback) => {
  try {
    const recaptcha_token = await recaptchaRef.current.execute()

    const body = Object.entries(values).reduce((accumulator, [name, value]) => {
      return accumulator + `<strong>${name}</strong>: ${value}<br />`
    }, "")
    console.log("body", body)

    await axios.post(`${process.env.WORDPRESS_BASE_URL}/superbom/v1/contacts`, {
      subject: 'Novo contato',
      body,
      recaptcha_token,
      post_id: post_id
    })

    showSuccess("Mensagem enviada!", "Em breve entraremos em contato. Obrigado!")
    sucessCallback()
  } catch (e) {
    showError(Translations.notifications.error_message, Translations.notifications.try_later)
  }
}

const recaptchaRef = React.createRef();

const resetForm = (values, form) => {
  setTimeout(() => {
    Object.keys(values).forEach(key => {
      form.resetFieldState(key)
    })
    form.reset()
  })
}

const ContactForm = ({
  button_id,
  post_id,
  last_input_name,
  last_input_placeholder,
  last_input_validation,
  text_name,
  text_placeholder,
  use_cities,
  send_email
}) => {
  const [showButtonId, setShowButtonId] = useState(false)

  const [states, setStates] = useState([])
  const [allCities, setAllCities] = useState([])
  const [cities, setCities] = useState([])

  const changeState = (value) => {
    const state = states.find(state => state.uf === value)
    if (state) {
      setCities(allCities.filter(city => city.state_id === state.id))
    }
  }

  useEffect(() => {
    axios.get(`/metadata/address.json`)
      .then((response) => {
        const loadedStates = response.data.states.map(s => Object.assign(s, { value: s.uf, label: s.name }))
        const loadedCities = response.data.cities.map(c => Object.assign(c, { key: c.id, value: c.name, label: c.name }))
        setStates(loadedStates)
        setAllCities(loadedCities)
        setCities(loadedCities)
      })
  }, [])

  return (
    <Form
      onSubmit={(values, form) => {
        if (send_email) {
          doSendEmail(post_id, values, () => {
            setShowButtonId(true)
            resetForm(values, form)
          })
        } else {
          setShowButtonId(true)
          showSuccess("Mensagem enviada!", "Em breve entraremos em contato. Obrigado!")
          resetForm(values, form)
        }
      }}
      render={({ handleSubmit, submitting, invalid }) => (
        <form
          onSubmit={handleSubmit}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.GOOGLE_RECAPTCHA_CLIENT_KEY}
          />

          <div className="columns">
            <div className="column">
              <InputField
                name="Nome"
                placeholder="Nome Completo"
                validate={validations.required}
              />
            </div>
            <div className="column">
              <InputField
                name="E-mail"
                placeholder="E-mail"
                validate={validations.email}
              />
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <InputField
                name="Telefone"
                placeholder="Telefone"
                validate={validations.phone}
                parse={parsers.phone}
              />
            </div>
            <div className="column">
              <InputField
                name={last_input_name}
                placeholder={last_input_placeholder}
                validate={validations[last_input_validation]}
                parse={parsers[last_input_validation]}
              />
            </div>
          </div>

          {use_cities &&
            <div className="columns">
              <div className="column">
                <div className={states.length === 0 ? "component-is-loading" : ""}>
                  <SelectField
                    name="Estado"
                    placeholder="Estado"
                    options={states}
                    onChange={changeState}
                    validate={validations.required}
                  />
                </div>
              </div>
              <div className="column">
                <div className={allCities.length === 0 ? "component-is-loading" : ""}>
                  <SelectField
                    name="Cidade"
                    placeholder="Cidade"
                    options={cities}
                    validate={validations.required}
                  />
                </div>
              </div>
              <div className="column">
                <InputField
                  name="CEP"
                  placeholder="CEP"
                  validate={validations.cep}
                  parse={parsers.cep}
                />
              </div>
            </div>}

          {text_name && text_placeholder &&
            <div className="columns">
              <div className="column">
                <TextareaField
                  name={text_name}
                  placeholder={text_placeholder}
                  validate={validations.required}
                />
              </div>
            </div>}

          <div>
            <button type="submit" id={showButtonId ? button_id : null} disabled={submitting || invalid} className={`button is-primary is-medium is-fullwidth is-rounded ${submitting && 'is-loading'}`}>
              <span className="icon is-small">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paper-plane"
                  className="svg-inline--fa fa-paper-plane fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path></svg>
              </span>
              <span className="has-text-weight-bold">Enviar</span>
            </button>
          </div>
        </form >
      )}
    />
  )
}

export default ContactForm
